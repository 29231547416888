<template>
  <div style="overflow:hidden;">
    <el-table
        :data='platforms'
        border>
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="logo_url" label="图片" width="120px" align="center">
        <template slot-scope="{row}">
          <el-image :src="row.logo_url" style="width: 40px;height: 40px"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="平台名称" align="center" width="120px"></el-table-column>
      <el-table-column
          label='操作' min-width='160' align='center'>
        <template slot-scope='scope'>
          <div class='col-opt'>
            <el-link type='warning' size='mini' @click='showPlcPage(scope.row)'
                     style="margin-left: 0;display: inline-block"
                     v-if="userPermissions.indexOf('journal')>-1"
                     icon='el-icon-setting'>刊例
            </el-link>
            <el-tooltip effect='dark' content='编辑' placement='top-start'
                        v-if="userPermissions.indexOf('journal_edit') > -1">
              <el-link style="margin-left: 10px;display: inline-block" type='primary' size='mini' icon='el-icon-edit'
                       @click='editButton(scope.row.id)'>修改
              </el-link>
            </el-tooltip>
            <el-link type='danger' size='mini' @click='handleDelete(scope.row)'
                     style="margin-left: 10px;display: inline-block"
                     v-if="userPermissions.indexOf('journal_delete')>-1"
                     icon='el-icon-delete'>删除
            </el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="float: right;padding: 6px 0">
      <el-button @click="addButton" type="primary" v-if="userPermissions.indexOf('journal_create') > -1"
                 class="el-icon-plus">新增刊例
      </el-button>
    </div>
    <ModalDialog :dialogData='dialogData' @dialogConfirm='handleConfirm' @dialogClose='dialogClose'>
      <template slot='content'>
        <el-form :model='formData' :rules='rules' ref='categoryForm' label-position='right' label-width='120px'>
          <el-form-item label='平台名称' prop='name'>
            <el-input v-model='formData.name'></el-input>
          </el-form-item>
          <el-form-item label='平台标识' prop='code'>
            <el-input v-model='formData.code'></el-input>
          </el-form-item>
          <el-form-item label='统一标识' prop='uq_code'>
            <el-input v-model='formData.uq_code' placeholder='统一标识用于系统（星广汇）之间数据匹配'></el-input>
          </el-form-item>
          <el-form-item label='平台官网' prop='url'>
            <el-input v-model='formData.website'></el-input>
          </el-form-item>
          <!--          <el-form-item label="备注" prop="description">-->
          <!--            <el-input type="textarea" v-model="formData.description"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label='Logo图片' prop='image' ref='imageItem'>
            <ApeUploader :limit='1' @handleUploadSuccess='handleUploadSuccess' @handleUploadRemove='handleUploadRemove'
                         :upload-file-list='uploadFileList'></ApeUploader>
          </el-form-item>
        </el-form>
      </template>
    </ModalDialog>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import ModalDialog from '@/components/ModalDialog.vue'
import ApeUploader from '@/components/ApeUploader.vue'

export default {
  name: 'PlatformsSetUp',
  components: { ApeUploader, ModalDialog },
  data() {
    return {
      platforms: [],
      dialogData: {
        visible: false,
        title: '',
        width: '40%',
        loading: true,
        modal: false
      },
      defaultFormData: {
        display_name: '',
        parent_id: 0
      },
      formData: {
        name: '',
        code: '',
        uq_code: '',
        website: '',
        is_data_entry: 1,
        is_plc_entry: 1,
        is_kol_entry: 1
      },
      rules: {
        code: [{ required: true, message: '输入平台标识', trigger: 'blur' }],
        name: [{ required: true, message: '输入平台名称', trigger: 'blur' }]
      },
      uploadFileList: []

    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  mounted() {
    this.getPlatforms()
  },
  methods: {
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getJournalList({})
      this.platforms = list
    },
    // 刊例配置
    showPlcPage(row) {
      this.dialogVisible = false
      this.$router.push({
        name: 'platform_plc_set', params: {
          platform_id: row.id
        }
      })
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['categoryForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      // console.log(file)
      // this.formData.image = file.id
      this.formData.logo_url = file.full_path
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.logo_url = null

      this.uploadFileList = fileList
    },
    async editButton(id) {
      this.dialogData.visible = true
      this.dialogData.title = '编辑分类'
      let { info } = await this.$api.getPlatformInfo(id)
      // if (info.parent_id) {
      //   this.rules.name[0].required = false
      // } else {
      //   this.rules.name[0].required = true
      // }
      this.formData = { ...info }
      this.uploadFileList = info.id ? [{ name: info.name, url: info.logo_url }] : []
      this.dialogData.loading = false
    },
    addButton() {
      this.dialogData.visible = true
      this.dialogData.title = '新增分类'
      this.formData = {}
      this.uploadFileList = []

      this.dialogData.loading = false

    },
    dialogClose() {
      this.dialogData.visible = false
      this.dialogData.loading = false
      // this.initData()
    },
    async formSubmit() {
      let id = await this.$api.getJournalStore(this.formData)
      if (id) {
        this.dialogData.visible = false
        this.getPlatforms()

      }
      this.msgSuccess('保存成功!')
      // this.$message.success('保存成功!')
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.delJournalStore({ id: row.id })
        await this.getPlatforms()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>


<style scoped>

</style>